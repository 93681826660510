import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { applyToPoint, Point, toCSS } from "transformation-matrix";
import { useBbox } from "../PanoramaTool/useBbox";
import { PinnedSvg1 } from "../shared/SvgIcons";
import { BOX_BORDER, PANEL_HEADER_HEIGHT, PIN_TYPES } from "./PanoramaModel";
import { ZoomPanelModel } from "./ZoomPanelModel";

type PProps = {
  point: [number, number];
  size?: number;
  color?: string;
};

const P = observer((props: PProps) => {
  let {
    point: [x, y],
    size,
    color,
  } = props;
  color = color || "red";
  size = size || 15;

  return (
    <div
      style={{
        position: "absolute",
        left: x - size / 2,
        top: y - size / 2,
        height: size,
        width: size,
        backgroundColor: color,
      }}
    />
  );
});

type Props = {
  model: ZoomPanelModel;
};

export const ZoomingPanelCanvasSvg = observer((props: Props) => {
  const { model } = props;
  const [bbox, ref] = useBbox();
  const [dimensions, setDimensions] = useState(null);

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      setDimensions(domNode.getClientRects());
    }
  }, []);
  console.info({ bbox, dimensions });
  const PIN_SIZE = 50;

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        left: 0,
        width: model.width,
        height: model.height,
        top: PANEL_HEADER_HEIGHT,
        overflow: "hidden",
        border: `1px solid red`,
      }}
    >
      <svg
        style={{
          overflow: "hidden",
          position: "absolute",
          left: 0,
          top: 0,
          width: model.width,
          height: model.height,
          backgroundColor: "white",
          ...BOX_BORDER,
        }}
        onMouseMove={(evt) => {
          const x = evt.nativeEvent.offsetX- bbox.x;
          const y = evt.nativeEvent.clientY - bbox.y;
          //const p = applyToPoint(model.inverceMatrix, { x, y });
          const p1 = [
            Math.round(x / model.matrix.a + model.inverceMatrix.e),
            Math.round(y / model.matrix.d + model.inverceMatrix.f),
          ];
          //console.info(
          //`move ${x} ${y} ${p1[0]},${p1[1]} `,
          //toCSS(model.matrix),
          //toCSS(model.inverceMatrix)
          //);
          model.setMoveLastPoint(p1 as Point);
          model.setMoveLastPointScr([x, y]);
        }}
        onDoubleClick={(evt) => {
          const x = evt.nativeEvent.offsetX - bbox.x;
          const y = evt.nativeEvent.clientY - bbox.y;
          const p1 = [
            Math.round(x / model.matrix.a + model.inverceMatrix.e),
            Math.round(y / model.matrix.d + model.inverceMatrix.f),
          ];
          console.info(
            `onDoubleClick(${evt.clientX}, ${evt.clientY}), ${x},${y}`
          );
          model.setPinAt(p1 as Point);
        }}
        onMouseLeave={() => {
          model.setMoveLastPoint(undefined);
          model.setMoveLastPointScr(undefined);
        }}
        onPointerDown={(evt) => model.onPointerDown(evt)}
        onWheel={(evt) => model.zoomStage(evt)}
        onDragOver={(evt) => evt.preventDefault()}
        onDrop={(evt) => {
          //console.info("drop", evt.clientX, evt.clientY);
          //const p = applyToPoint(model.matrix, [evt.clientX, evt.clientY]);
          //const p1 = applyToPoint(model.inverceMatrix, [
          //evt.clientX,
          //evt.clientY,
          //]);
          //console.info("drop", p, p1, evt.clientX, evt.clientY);
          evt.preventDefault();
        }}
        viewBox={`0 0 ${model.width} ${model.height}`}
      >
        {!model.error && (
          <>
            <g transform={toCSS(model.matrix)}>
              <image
                href={model.image.src}
                width={model.image.width}
                height={model.image.height}
              />
            </g>
            {Array.from(model.pinMap.keys()).map((name) => {
              const color = PIN_TYPES.find((p) => p.name == name).color;
              const point = model.pinMap.get(name);
              const p = applyToPoint(model.matrix, point);
              return (
                <g
                  key={name}
                  transform={`translate(${p[0] - PIN_SIZE / 2}, ${
                    p[1] - PIN_SIZE
                  })`}
                >
                  <PinnedSvg1 style={{}} color={color} size={PIN_SIZE} />
                </g>
              );
            })}
            <P point={applyToPoint(model.inverceMatrix, [0, 0])} />
            <P point={applyToPoint(model.matrix, [0, model.image.height])} />
            <P
              point={applyToPoint(model.matrix, [
                model.image.width,
                model.image.height,
              ])}
            />
            <P point={applyToPoint(model.matrix, [model.image.width, 0])} />
          </>
        )}
      </svg>
    </div>
  );
});
