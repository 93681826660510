import React, { useState } from "react";
import { Point } from "transformation-matrix";
import { PanoramaTool } from "../PanoramaTool/PanoramaTool";

export const PanoramaToolPreview = () => {
  const [points, setPoints] = useState<[Array<Point>, Array<Point>]>([
    [
      [100, 100],
      [100, 200],
    ],
    [
      [100, 100],
      [100, 200],
    ],
  ]);
  const [offset, setOffset] = useState(100);

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
        padding: "4%",
      }}
    >
      <PanoramaTool
        style={{ width: "95%", height: `95%` }}
        points={points}
        leftImageSource={"/pano/camera0.jpeg"}
        rightImageSource={"/pano/camera1.jpeg"}
        offset={offset}
        setPoints={setPoints}
        setOffset={setOffset}
        onOK={() => {}}
        onCancel={() => {}}
        onRefresh={() => {}}
      />
    </div>
  );
};
