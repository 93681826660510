import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ZoomPanelModel } from "./ZoomPanelModel";
import { ZoomingPanelHeader } from "./ZoomingPanelHeader";
import { ZoomingPanelCanvasSvg } from "./ZoomingPanelCanvasSvg";

type Props = {
  panel: ZoomPanelModel;
};

export const ZoomingPanel = observer((props: Props) => {
  const { panel } = props;

  return (
    <div
      style={{
        position: "absolute",
        overflow: "hidden",
        ...panel.rect,
      }}
    >
      {panel && (
        <>
          <ZoomingPanelHeader model={panel} />
          <ZoomingPanelCanvasSvg model={panel} />
        </>
      )}
    </div>
  );
});
