import React, { CSSProperties, useState } from "react";
import { PreviewPanel } from "./PreviewPanel";
import { PrimaryToolbar } from "./PrimaryToolbar";
import { SecondaryToolbar } from "./SecondaryToolbar";
import "./PanoramaTool.css";
import {
  applyToPoint,
  identity,
  inverse,
  Matrix,
  Point,
} from "transformation-matrix";
import { useBbox } from "./useBbox";

type Props = {
  leftImageSource: string;
  rightImageSource: string;
  points: [Array<Point>, Array<Point>];
  style: CSSProperties;
  setPoints(points: [Array<Point>, Array<Point>]): void;
  offset: number;
  setOffset: (v: number) => void;
  onOK: () => void;
  onCancel: () => void;
  onRefresh: () => void;
};

export const PanoramaTool = (props: Props) => {
  const {
    style,
    points,
    leftImageSource,
    rightImageSource,
    offset,
    setOffset,
    setPoints,
  } = props;
  const [leftMatrix, setLeftMatrix] = useState(identity());
  const [rightMatrix, setRightMatrix] = useState(identity());
  const [leftBBox, refLeftCanvas] = useBbox();
  const [rightBBox, refRightCanvas] = useBbox();
  const [selectedPinIndex, setSelectedPinIndex] = useState<number>(-1);
  const calcPoint = (bbox: any, matrix: Matrix) => {
    const inv = inverse(matrix);
    const point = applyToPoint(inv, [bbox.width / 2, bbox.height / 2]);
    return point;
  };
  const addPoint = () => {
    const leftPoint = calcPoint(leftBBox, leftMatrix);
    const rightPoint = calcPoint(rightBBox, rightMatrix);
    setPoints([
      [...points[0], leftPoint],
      [...points[1], rightPoint],
    ]);
    setSelectedPinIndex(points[0].length);
  };
  return (
    <div className="panorama-tool-container" style={{ ...style }}>
      <PrimaryToolbar />
      <SecondaryToolbar
        points={points}
        offset={offset}
        setSelectedPinIndex={setSelectedPinIndex}
        selectedPinIndex={selectedPinIndex}
        addPoint={addPoint}
        setOffset={setOffset}
        setPoints={setPoints}
      />
      <PreviewPanel
        position="left"
        points={points}
        selectedPinIndex={selectedPinIndex}
        setSelectedPinIndex={setSelectedPinIndex}
        setPoints={setPoints}
        imageSource={leftImageSource}
        matrix={leftMatrix}
        setMatrix={setLeftMatrix}
        refCanvas={refLeftCanvas}
        bboxCanvas={leftBBox}
      />
      <PreviewPanel
        imageSource={rightImageSource}
        points={points}
        setPoints={setPoints}
        position="right"
        setSelectedPinIndex={setSelectedPinIndex}
        selectedPinIndex={selectedPinIndex}
        matrix={rightMatrix}
        setMatrix={setRightMatrix}
        refCanvas={refRightCanvas}
        bboxCanvas={rightBBox}
      />
    </div>
  );
};
