import { range } from "lodash";
import { makeAutoObservable, observable, reaction } from "mobx";
import clr from "colors.css";
import { PinModel } from "./PinModel";
import {
  scale,
  identity,
  translate,
  compose,
  applyToPoint,
  inverse,
  Point,
  fromTriangles,
  fromObject,
} from "transformation-matrix";
import { PanoramaModel } from "./PanoramaModel";

const names = Object.keys(clr);
const scaleBy = 1.1;

export class ZoomPanelModel {
  pins = observable.array([
    ...range(10).map((n) => new PinModel(clr[names[n]], names[n])),
  ]);
  image?: HTMLImageElement;
  matrix = identity();
  panorama: PanoramaModel;
  index: 0 | 1;
  moveLastPoint?: Point = undefined;
  moveLastPointScr?: Point = undefined;
  pinMap = observable.map();
  error: any = false;
  indexString: string;

  constructor(panorama: PanoramaModel, index: 0 | 1) {
    this.panorama = panorama;
    this.index = index;
    this.indexString = ""+this.index;
    makeAutoObservable(this);

    reaction(
      () => this.imagePath,
      () => {
        if (typeof window !== "undefined") {
          this.image = new window.Image();
          this.image.src = this.imagePath;
          this.image.onload = () => this.onImageLoad();
          this.image.onerror = (e) => this.onImageError(e);
        }
      },
      { delay: 0.1, fireImmediately: true }
    );
  }

  get imagePath() {
    return this.panorama.getImagePath(this.indexString);
  }

  setIndexString(s:string) {
    this.indexString = s;
  }

  setMoveLastPoint(p?: Point) {
    this.moveLastPoint = p;
  }

  setMoveLastPointScr(p?: Point) {
    this.moveLastPointScr = p;
  }

  get width() {
    return this.rect.width;
  }

  get height() {
    return this.rect.height;
  }

  setPinAt(point: Point) {
    this.pinMap.set(this.panorama.activePinName, point);
  }

  get rect() {
    return this.panorama.getZoomPanelRect(this.index);
  }

  get zoom() {
    return this.matrix.a;
  }

  setZoom(zoom: number) {
    const m = fromObject(this.matrix);
    m.a = m.d = zoom;
    this.setMatrix(m);
  }

  fit() {
    const s = this.width / this.image.width;

    const m = fromTriangles(
      [
        [0, 0],
        [this.image.width, 0],
        [this.image.width, this.image.height],
      ],
      [
        [0, 0],
        [this.image.width * s, 0],
        [this.image.width * s, this.image.height * s],
      ]
    );
    this.setMatrix(m);
  }

  onImageLoad() {
    this.fit();
    this.error = null;
  }

  onImageError(e) {
    this.error = e;
  }

  setMatrix(matrix) {
    this.matrix = matrix;
  }

  get addedPins() {
    const res = this.pins.filter((p) => p.isAdded);
    return res;
  }

  onPointerDown(evt) {
    console.info("onPointerDown");
    const downMatix = this.matrix;
    const downX = evt.nativeEvent.screenX;
    const downY = evt.nativeEvent.screenY;

    const move = (evt) => {
      const m = compose(
        downMatix,
        translate(
          (evt.screenX - downX) / this.matrix.a,
          (evt.screenY - downY) / this.matrix.a
        )
      );
      this.setMatrix(m);
    };

    const up = (evt) => {
      console.info("up");
      window.removeEventListener("pointermove", move);
      window.removeEventListener("pointerup", up);
    };

    window.addEventListener("pointermove", move);
    window.addEventListener("pointerup", up);
  }

  get inverceMatrix() {
    return inverse(this.matrix);
  }

  zoomStage(event) {
    //event.preventDefault();
    const inv = inverse(this.matrix);
    const p = [event.nativeEvent.offsetX, event.nativeEvent.offsetY];
    const pointOnImage = applyToPoint(inv, p as Point);
    const scaleUpdate = event.nativeEvent.deltaY > 0 ? 1 / scaleBy : scaleBy;
    const pp = pointOnImage;
    const newMatrix = compose(
      this.matrix,
      scale(scaleUpdate, scaleUpdate, pp[0], pp[1])
    );

    this.setMatrix(newMatrix);
  }
}
