import { fromTriangles, Matrix } from "transformation-matrix";

export function fit(
  image: {width:number, height:number},
  width: number,
  height: number
): Matrix {
  const s = width / image.width;

  const m = fromTriangles(
    [
      [0, 0],
      [image.width, 0],
      [image.width, image.height],
    ],
    [
      [0, 0],
      [image.width * s, 0],
      [image.width * s, image.height * s],
    ]
  );
  return m;
}
