import React, { useEffect } from "react";
import { observer, PropTypes } from "mobx-react";
import { PanoramaModel, PIN_TYPES } from "./PanoramaModel";

type Props = {
  panorama: PanoramaModel;
};

export const PinSelect = observer((props: Props) => {
  const { panorama } = props;
  return (
    <div className="row g-2" style={{border: '0px solid blue'}}>
      {PIN_TYPES.map((p) => (
        <div key={p.name} className="col-auto">
          <label className="form-colorinput">
            <input
              name="color-rounded"
              type="radio"
	      checked={panorama.activePinName===p.name}
	      onChange={evt => panorama.setActivePinName(evt.target.value)}
              value={p.name}
              className="form-colorinput-input"
            />
            <span
              style={{ backgroundColor: p.color }}
              className="form-colorinput-color rounded-circle"
            ></span>
          </label>
        </div>
      ))}
      |
    </div>
  );
});
