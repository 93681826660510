import { isNumber } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { BOX_BORDER, PANEL_HEADER_HEIGHT } from "./PanoramaModel";
import { ZoomPanelModel } from "./ZoomPanelModel";

const ToolbarButton = ({
  title,
  onPress,
}: {
  title: string;
  onPress: () => void;
}) => (
  <div className="col-6 col-sm-4 col-md-2 col-xl mb-3">
    <a
      href="#"
      onClick={(evt) => {
        evt.preventDefault();
        onPress();
      }}
      className="btn btn-md btn-outline-secondary w-100"
    >
      {title}
    </a>
  </div>
);
type Props = {
  model: ZoomPanelModel;
};

export const ZoomingPanelHeader = observer((props: Props) => {
  const { model } = props;
  const [zoomStr, setZoomStr] = useState(model.zoom.toString());
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setZoomStr(model.zoom.toString());
  }, [model.zoom]);

  /*<{model.pins.map((p) => (
        <div
          style={{
            border: `1px solid ${p.isAdded ? "silver" : "black"}`,
            margin: 3,
            padding: 3,
          }}
          draggable
          key={p.id}
          onDragStart={() => {}}
        >
          <PinnedSvg key={p.id} color={p.color} />
        </div>
      ))}
      <div style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
        [{}]
        [{toCSS(smoothMatrix(model.matrix, 5))}]
        [{toCSS(smoothMatrix(model.inverceMatrix, 5))}]
      </div>*/
  return (
    <div
      style={{
        height: PANEL_HEADER_HEIGHT,
        display: "flex",
        backgroundColor: "white",
        ...BOX_BORDER,
        borderBottomWidth: 0,
      }}
    >
      <div
        className="row g-2 align-items-center mb-n3"
        style={{ padding: 4, display: "flex", alignItems: "center" }}
      >
        <ToolbarButton title="Fit" onPress={() => model.fit()} />
        <ToolbarButton title="100%" onPress={() => model.setZoom(1)} />[
        {`left,top,width,height`
          .split(",")
          .map((k) => Math.round(model.rect[k]))
          .join(",")}
        ]
        <div className="col-6 col-sm-4 col-md-2 col-xl mb-3">
          <input
            className={`form-control ${false ? "is-invalid" : ""}`}
            type="text"
            value={model.indexString}
            onChange={(evt) => {
              model.setIndexString(evt.target.value);
            }}
          />
        </div>
        <div className="col-6 col-sm-4 col-md-2 col-xl mb-3">
          <input
            className={`form-control ${isError ? "is-invalid" : ""}`}
            type="number"
            value={zoomStr}
            onChange={(evt) => {
              const zstr = (evt.nativeEvent.target as any).value;
              const zoom = parseFloat(zstr);
              if (isNumber(zoom) && zoom > 0) {
                model.setZoom(zoom);
                setIsError(false);
              } else {
                setIsError(true);
              }
              setZoomStr(zstr);
            }}
            name="zoom"
            min="0.01"
            max="20"
          />
        </div>
      </div>
    </div>
  );
});
