import React, { useEffect } from "react";
import { observer, PropTypes } from "mobx-react";
import { FullScreen } from "../layouts/FullScreen";
import { ZoomingPanel } from "./ZoomingPanel";
import { HEADER_HEIGHT, PanoramaModel } from "./PanoramaModel";
import { BottomPanel } from "./BottomPanel";
import { Footer } from "./Footer";
import { Header } from "./Header";

type Props = {
  width: number;
  height: number;
  panorama: PanoramaModel;
};

export const PanoramaContent = observer((props: Props) => {
  const { width, height, panorama } = props;
  useEffect(() => {
    panorama.setSize(width, height);
  }, [width, height]);
  return (
    <div style={{backgroundColor: `rgb(244, 246, 250)`, left: 0, top: 0, width, height}}>
      <BottomPanel panorama={panorama} />
      <ZoomingPanel panel={panorama.panels[0]} />
      <ZoomingPanel panel={panorama.panels[1]} />
      <Footer panorama={panorama} />
      <Header panorama={panorama} />
    </div>
  );
});
