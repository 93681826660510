import { uniqueId } from "lodash";
import { makeAutoObservable } from "mobx";

export class PinModel {
  x?: number = undefined;
  y?: number = undefined;
  id = uniqueId("pin");
  name: string;
  color: string;

  constructor(color: string, name: string) {
    this.name = name;
    this.color = color;
    makeAutoObservable(this );
  }

  get isAdded() {
    const res = this.y != undefined;
    return res;
  }

  setCoord(x?: number, y?: number) {
    this.x = x;
    this.y = y;
  }
}