import React, {  useState } from "react";
import { observer } from "mobx-react";
import { FullScreen } from "../layouts/FullScreen";
import {  PanoramaModel } from "./PanoramaModel";
import { PanoramaContent } from "./PanoramaContent";

export const Panorama = observer(() => {
  const [panorama] = useState(new PanoramaModel());
  const renderContent = (width: number, height: number) => {
    if (panorama && width > 0) {
      return (
        <PanoramaContent width={width} height={height} panorama={panorama} />
      );
    } else {
      return <div />;
    }
  };
  return <FullScreen renderContent={renderContent} />;
});

export default Panorama;
