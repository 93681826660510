import { range } from "lodash";
import React from "react";
import { Point } from "transformation-matrix";
import { PIN_TYPES } from "./settings";

type Props = {
  points: [Array<Point>, Array<Point>];
  setPoints(points: [Array<Point>, Array<Point>]): void;
  selectedPinIndex: number;
  setSelectedPinIndex: (n: number) => void;
  addPoint: () => void;
};

export const PointsToolbar = (props: Props) => {
  const { addPoint, points, setPoints, selectedPinIndex, setSelectedPinIndex } =
    props;
  const count = points[0].length;
  const shouldAllowAdd = count < 10;
  const size = 24;
  const itemStyle = {
    height: size,
    width: size,
    borderRadius: size / 2,
    color: "white",
    margin: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
  };

  const toggleSelected = (n: number) => {
    if (selectedPinIndex === n) {
      setSelectedPinIndex(-1);
    } else {
      setSelectedPinIndex(n);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {range(0, count).map((n) => (
        <a
          key={n}
          href="#"
          onClick={(evt) => {
            evt.preventDefault();
            toggleSelected(n);
          }}
        >
          <div
            style={{
              ...itemStyle,
              outline: `${n === selectedPinIndex ? 1 : 0}px solid black`,
              outlineOffset: 3,
              backgroundColor: PIN_TYPES[n].color,
            }}
          >
            {n + 1}
          </div>
        </a>
      ))}
      {shouldAllowAdd && (
        <a
          href="#"
          onClick={(evt) => {
            evt.preventDefault();
            addPoint();
          }}
        >
          <div
            style={{
              ...itemStyle,
              backgroundColor: "white",
              border: "1px solid black",
              color: "black",
            }}
          >
            +
          </div>
        </a>
      )}
    </div>
  );
};
