export const PIN_TYPES = [
  "ff0000",
  "ff8700",
  "ffd300",
  "deff0a",
  "a1ff0a",
  "0aff99",
  "0aefff",
  "147df5",
  "580aff",
  "be0aff",
].map((color) => ({ name: color, color: `#${color}` }));