import React from "react";
import { observer } from "mobx-react";
import { BOX_BORDER, PanoramaModel } from "./PanoramaModel";
import { Point } from "transformation-matrix";

const LastPoint = observer(({ point }: { point: Point }) => {
  return (
    <span>
      [{Math.floor(point[0])}, {Math.floor(point[1])}]
    </span>
  );
});

type Props = {
  panorama: PanoramaModel;
};

export const Footer = observer((props: Props) => {
  const { panorama } = props;
  return (
    <div
      style={{
        position: "absolute",
        ...panorama.footerRect,
        borderTop: BOX_BORDER.border,
      }}
    >
      {panorama.panels[0].moveLastPointScr? (
        <LastPoint point={panorama.panels[0].moveLastPointScr} />
      ) : (
        ""
      )}
      {panorama.panels[1].moveLastPointScr ? (
        <LastPoint point={panorama.panels[1].moveLastPointScr} />
      ) : (
        ""
      )}
      {panorama.panels[0].moveLastPoint ? (
        <LastPoint point={panorama.panels[0].moveLastPoint} />
      ) : (
        ""
      )}
      {panorama.panels[1].moveLastPoint ? (
        <LastPoint point={panorama.panels[1].moveLastPoint} />
      ) : (
        ""
      )}
    </div>
  );
});
