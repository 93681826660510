import React, { useEffect, useState } from "react";
import { Matrix, inverse, fromTriangles, Point } from "transformation-matrix";
import { Footer } from "./Footer";
import { fit } from "./fit";
import { PreviewCanvas } from "./PreviewCanvas";

const scaleBy = 1.1;

type Props = {
  position: "left" | "right";
  matrix: Matrix;
  setMatrix: (m: Matrix) => void;
  imageSource: string;
  points: [Array<Point>, Array<Point>];
  setPoints(points: [Array<Point>, Array<Point>]): void;
  selectedPinIndex: number;
  setSelectedPinIndex: (n: number) => void;
  refCanvas: any;
  bboxCanvas: any;
};

export const PreviewPanel = (props: Props) => {
  const {
    position,
    matrix,
    setMatrix,
    imageSource,
    points,
    setPoints,
    selectedPinIndex,
    setSelectedPinIndex,
    refCanvas,
    bboxCanvas: bbox,
  } = props;
  const inverceMatrix = inverse(matrix);
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    if (bbox.width > 0) {
      const image = new Image();
      image.src = imageSource;
      image.onload = () => {
        const m = fit(image, bbox.width, bbox.height);
        setImage(image);
        setMatrix(m);
      };
    }
  }, [imageSource, bbox.width > 0]);

  return (
    <>
      <PreviewCanvas
        imageSource={imageSource}
        points={points}
        setPoints={setPoints}
        position={position}
        matrix={matrix}
        setMatrix={setMatrix}
        selectedPinIndex={selectedPinIndex}
        setSelectedPinIndex={setSelectedPinIndex}
        elementRef={refCanvas}
        bbox={bbox}
        image={image}
      />
      <Footer
        position={position}
        matrix={matrix}
        setMatrix={setMatrix}
        bbox={bbox}
        image={image}
      />
    </>
  );
};
