import React from "react";
import { Matrix, Point } from "transformation-matrix";
import { Pin } from "./Pin";

type Props = {
  points: [Array<Point>, Array<Point>];
  setPoints(points: [Array<Point>, Array<Point>]): void;
  position: "left" | "right";
  matrix: Matrix;
  selectedPinIndex: number;
  setSelectedPinIndex: (n: number) => void;
  setPreventPanning: (v: boolean) => void;
};

export const Pins = (props: Props) => {
  const {
    points,
    setPoints,
    position,
    matrix,
    selectedPinIndex,
    setPreventPanning,
  } = props;
  const indexPoints = position == "left" ? 0 : 1;

  return (
    <>
      {points[indexPoints].map((p: Point, i: number) => {
        const setPoint = (p: Point) => {
          const positionIndex = position == "left" ? 0 : 1;
          const res = points.map((arr: Array<Point>, arrIndex: number) =>
            arr.map((pp: Point, ii) => {
              if (arrIndex === positionIndex && ii === i) {
                return p;
              } else {
                return pp;
              }
            })
          ) as [Point[], Point[]];
          //console.info(`setPoint ${p},${i},${position} res=${res}`);
          setPoints(res);
        };
        return (
          <Pin
            setPoint={setPoint}
            key={i}
            point={p}
            index={i}
            matrix={matrix}
            selectedPinIndex={selectedPinIndex}
            setPreventPanning={setPreventPanning}
          />
        );
      })}
    </>
  );
};
