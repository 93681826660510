import { template } from "lodash";
import { makeAutoObservable } from "mobx";
import { calcCrop } from "./calcCrop";
import { ZoomPanelModel } from "./ZoomPanelModel";

export const MARGIN = 2;
export const HEADER_HEIGHT = 50;
export const FOOTER_HEIGHT = 50;
export const PANEL_HEADER_HEIGHT = 44;
export const BOTTOM_PANEL_PERCENT = 30;
export const BOX_BORDER = {
  boxShadow: "rgb(35 46 60 / 4%) 0 2px 4px 0",
  border: "1px solid rgba(101,109,119,.16)",
};

/*export const PIN_TYPES = [
  { color: "#206bc4", name: "top" },
  { color: "#d63939", name: "bottom" },
  { color: "#2fb344", name: "labelA" },
  { color: "#f59f00", name: "labelB" },
  { color: "#17a2b8", name: "labelC" },
];*/

export const PIN_TYPES = [
  "ff0000",
  "ff8700",
  "ffd300",
  "deff0a",
  "a1ff0a",
  "0aff99",
  "0aefff",
  "147df5",
  "580aff",
  "be0aff",
].map((color) => ({ name: color, color: `#${color}` }));
PIN_TYPES[0].name = "top";
PIN_TYPES[1].name = "bottom";

export class PanoramaModel {
  width = 0;
  height = 0;
  offset = 50;
  activePinName = PIN_TYPES[0].name;
  imagesTemplateString =
    "/pano/camera<%= cameraIndex %>.jpeg?timestamp=<%= timestamp %>";
  timestamp = new Date().getTime();

  panels = [new ZoomPanelModel(this, 0), new ZoomPanelModel(this, 1)];

  constructor() {
    makeAutoObservable(this);
  }

  updateTimestamp() {
    this.timestamp = new Date().getTime();
  }

  getImagePath(indexString: string) {
    const str = this.imagesTemplate({
      cameraIndex: indexString,
      timestamp: this.timestamp,
    });
    return str;
  }

  setOffset(v: number): void {
    this.offset = v;
  }

  setImagesTemplateString(str) {
    this.imagesTemplateString = str;
  }

  get imagesTemplate() {
    return template(this.imagesTemplateString);
  }

  setActivePinName(name: string) {
    this.activePinName = name;
  }

  get activePin() {
    return PIN_TYPES.find((p) => p.name === this.activePinName);
  }

  setSize(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  get headerRect() {
    return { left: 0, width: this.width, height: HEADER_HEIGHT, top: 0 };
  }
  get mainRect() {
    const top = HEADER_HEIGHT + MARGIN;
    const height = this.height - top - FOOTER_HEIGHT - MARGIN;
    return { left: 0, width: this.width, height, top };
  }

  get zoomPanelsRect() {
    const height = Math.floor(
      ((100 - BOTTOM_PANEL_PERCENT) * this.mainRect.height) / 100
    );
    return { left: 0, top: this.mainRect.top, height, width: this.width };
  }

  get footerRect() {
    return {
      left: 0,
      width: this.width,
      top: this.height - FOOTER_HEIGHT,
      height: FOOTER_HEIGHT,
    };
  }

  getZoomPanelRect(i: 0 | 1) {
    let res = { ...this.zoomPanelsRect };
    res.width = (res.width - 3 * MARGIN) / 2;
    res.left = MARGIN + (MARGIN + res.width) * i;
    return res;
  }

  get bottomPanelRect() {
    const top = this.zoomPanelsRect.top + this.zoomPanelsRect.height + MARGIN;
    const height = this.footerRect.top - top - MARGIN;
    return { top, left: MARGIN, width: this.width - 2 * MARGIN, height };
  }

  get completedPins() {
    return PIN_TYPES.filter(
      (p) =>
        this.panels[0].pinMap.has(p.name) && this.panels[1].pinMap.has(p.name)
    );
  }

  get canCalcOld() {
    return this.panels.every(
      (p) => p.pinMap.has("top") && p.pinMap.has("bottom")
    );
  }

  get cropInfoOld() {
    const cropInfo = this.canCalcOld && calcCrop(this);
    return cropInfo;
  }

  /*get canCalc() {
    return this.panels.every(
      (p) => p.pinMap.has("top") && p.pinMap.has("bottom")
    );
  }*/

  get cropInfo() {
    //const cropInfo = this.canCalc && calcCrop(this);
    //return cropInfo;
    const p1 = this.panels[0];
    const p2 = this.panels[1];
    const res = {
      images: [
        {
          width: p1.image.width,
          height: p1.image.height,
          right: [],
        },
        {
          width: p2.image.width,
          height: p2.image.height,
          left: [],
        },
      ],
      offsets: [this.offset],
    };
    for (const info of PIN_TYPES) {
      const i1: [number, number] = p1.pinMap.get(info.name);
      const i2: [number, number] = p2.pinMap.get(info.name);
      if (i1 && i2) {
        res.images[0].right.push({ x: i1[0], y: i1[1] });
        res.images[1].left.push({ x: i2[0], y: i2[1] });
      }
    }
    return res;
  }

  get cropJsonText() {
    const f = (p: ZoomPanelModel) =>
      Array.from(p.pinMap.keys()).reduce((m, n) => {
        m[n] = { x: p.pinMap.get(n)[0], y: p.pinMap.get(n)[1] };
        return m;
      }, {});
    return JSON.stringify(
      {
        "image-sizes": this.panels.map((p) => ({
          width: p.image.width,
          height: p.image.height,
        })),
        lines: [
          {
            left: f(this.panels[0]),
            right: f(this.panels[1]),
          },
        ],
      },
      null,
      2
    );
  }

  addDebugPins() {
    this.panels[0].pinMap.set("top", [2920, 384]);
    this.panels[0].pinMap.set("bottom", [2979, 1500]);
    this.panels[1].pinMap.set("top", [84, 306]);
    this.panels[1].pinMap.set("bottom", [52, 1460]);
  }
}
