import React, {useRef, useEffect, useState, Ref, LegacyRef, RefObject} from 'react';

export const useBbox:() => [any, RefObject<HTMLDivElement|null>] = () => {
  const ref = useRef<HTMLDivElement|null>(null);
  const [bbox, setBbox] = useState({});

  const set = () =>
    setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [bbox, ref];
};
