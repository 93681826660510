import React, { useEffect, useState } from "react";
import { observer, PropTypes } from "mobx-react";
import { BOX_BORDER, HEADER_HEIGHT, PanoramaModel } from "./PanoramaModel";
import { PinSelect } from "./PinSelect";
import { isNumber } from "lodash";

type Props = {
  panorama: PanoramaModel;
};

export const Header = observer((props: Props) => {
  const { panorama } = props;
  const [offsetValue, setOffsetValue] = useState("");
  const [offsetError, setOffsetError] = useState("");
  useEffect(() => {
    setOffsetValue("" + panorama.offset);
  }, [panorama.offset]);
  console.info(`HEADER`);
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: `white`,
        ...panorama.headerRect,
        ...BOX_BORDER,
        borderWidth: 0,
        borderBottomWidth: 1,
        display: "flex",
        padding: 10,
        justifyItems: "center",
      }}
    >
      <PinSelect panorama={panorama} />
      <input
        className="form-control"
        style={{ width: "45%" }}
        value={panorama.imagesTemplateString}
        onChange={(evt) => panorama.setImagesTemplateString(evt.target.value)}
      />
      <button className="btn" onClick={() => panorama.updateTimestamp()}>
        Refresh
      </button>
      <button className="btn" onClick={() => panorama.addDebugPins()}>
        Add Debug Pins
      </button>
      <div className="input-group" style={{ width: 200 }}>
        <span className="input-group-text">Offset</span>
        <input
          style={{ width: 80 }}
          type="text"
          className={`form-control ${offsetError && "is-invalid"}`}
          placeholder="offset"
          value={offsetValue}
          onChange={(evt) => {
            setOffsetValue(evt.target.value);
            const num = parseFloat(evt.target.value);
            if (isNumber(num) && num >= 0 && num <= 100) {
              panorama.setOffset(num);
              setOffsetError("");
            } else {
              setOffsetError("err");
            }
          }}
        />
      </div>
    </div>
  );
});
