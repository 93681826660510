import React, { Component, useEffect, useRef, useState } from "react";
import { Matrix, Point, applyToPoint } from "transformation-matrix";
import { PIN_TYPES } from "./settings";
import { PinnedSvg1 } from "../shared/SvgIcons";

const PIN_SIZE = 50;
const DRAGGABLE_ARREA_SIZE = 30;

type Props = {
  matrix: Matrix;
  selectedPinIndex: number;
  setPreventPanning: (v: boolean) => void;
  point: Point;
  setPoint: (p: Point) => void;
  index: number;
};

type State = {
  shift: Point;
};

export class Pin extends Component<Props, State> {
  ref = React.createRef<SVGGElement>();
  pressedAt: Point | null = null;

  constructor(props) {
    super(props);
    this.state = { shift: [0, 0] };
  }

  onPointerMove = (evt: any) => {
    //console.info("Pin.move", point, pressedAt /*, evt*/);
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.pressedAt) {
      return;
    }
    const shift = [
      evt.clientX - this.pressedAt[0],
      evt.clientY - this.pressedAt[1],
    ] as [number, number];
    this.setState({ ...this.state, shift });
  };

  onPointerUp = (evt: any) => {
    const { setPreventPanning, matrix, setPoint, point } = this.props;
    window.removeEventListener("pointerup", this.onPointerUp);
    window.removeEventListener("pointermove", this.onPointerMove);
    evt.preventDefault();
    evt.stopPropagation();
    setPreventPanning(false);
    //console.info("Pin.up");
    const scale = 1 / matrix.a;
    const shift = [
      scale * (evt.clientX - this.pressedAt[0]),
      scale * (evt.clientY - this.pressedAt[1]),
    ] as [number, number];
    setPoint([
      Math.round(point[0] + shift[0]),
      Math.round(point[1] + shift[1]),
    ]);
    this.setState({ ...this.state, shift: [0, 0] });
    this.pressedAt = null;
  };

  onPointerDown = (evt: any) => {
    const { index: i, selectedPinIndex, setPreventPanning } = this.props;
    const selected = selectedPinIndex === i;
    //console.info("onPointerDown selected=", selected, i, selectedPinIndex);
    evt.preventDefault();
    evt.stopPropagation();
    if (!selected) {
      return false;
    }
    const p = [evt.clientX, evt.clientY] as [number, number];
    this.pressedAt = p;
    setPreventPanning(true);
    window.addEventListener("pointermove", this.onPointerMove);
    window.addEventListener("pointerup", this.onPointerUp);
  };

  render() {
    const {
      point,
      index: i,
      matrix,
      selectedPinIndex,
      setPoint,
      setPreventPanning,
    } = this.props;
    const color = PIN_TYPES[i].color;
    const p = applyToPoint(matrix, point);
    const shiftedP = [p[0] + this.state.shift[0], p[1] + this.state.shift[1]];
    const selected = selectedPinIndex === i;
    let cursor = "default";
    if (selected) {
      cursor = "grab";
    }
    //console.info("cursor", cursor, pressedAt, "handler=", onPointerDown.current);

    return (
      <>
        {selected && (
          <>
            <circle
              cx={shiftedP[0]}
              cy={shiftedP[1]}
              r={DRAGGABLE_ARREA_SIZE / 2 + 1}
              stroke={"white"}
              fill={"rgba(0, 0, 0, 0.01)"}
              style={{ cursor }}
              onPointerDown={this.onPointerDown}
            />
            <circle
              cx={shiftedP[0]}
              cy={shiftedP[1]}
              r={DRAGGABLE_ARREA_SIZE / 2}
              stroke={"black"}
              style={{ cursor }}
              fill={"none"}
              onPointerDown={this.onPointerDown}
            />
            <circle
              cx={shiftedP[0]}
              cy={shiftedP[1]}
              r={DRAGGABLE_ARREA_SIZE / 2 - 1}
              stroke={"white"}
              style={{ cursor }}
              fill={"none"}
              onPointerDown={this.onPointerDown}
            />
          </>
        )}
        <g
          ref={this.ref}
          onPointerDown={this.onPointerDown}
          style={{ cursor }}
          key={i}
          transform={`translate(${shiftedP[0] - PIN_SIZE / 2}, ${
            shiftedP[1] - PIN_SIZE
          })`}
        >
          <PinnedSvg1 style={{}} color={color} size={PIN_SIZE} />
        </g>
      </>
    );
  }
}
