import { ReactNode } from "react";
import "@tabler/core/dist/css/tabler.min.css";
import useDimensions from "react-use-dimensions";
import React from "react";
import { observer } from "mobx-react";

type LayoutProps = {
  renderContent: (width: number, height: number) => ReactNode;
};

export const FullScreen = observer(({ renderContent }: LayoutProps) => {
  const [ref, { width, height }] = useDimensions();
  return (
    <>
      <div
        ref={ref}
        className="page antialiased border-top-wide "
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
        }}
      >
        {renderContent(width, height)}
      </div>
    </>
  );
});
