import { observer } from "mobx-react";
import { BOX_BORDER, PanoramaModel } from "./PanoramaModel";
import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";

type Props = {
  panorama: PanoramaModel;
};
type Tabs = "pins" | "result old" | "result";
export const BottomPanel = observer((props: Props) => {
  const { panorama } = props;
  const [tab, setTab] = useState<Tabs>("pins");
  useEffect(() => {
    Prism.highlightAll();
  }, [panorama.cropJsonText, tab]);

  const Tab = ({ name }) => (
    <li className="nav-item">
      <a
        href="#"
        className={`nav-link ${name === tab ? "active" : ""} `}
        onClick={(evt) => {
          evt.preventDefault();
          setTab(name);
        }}
        data-bs-toggle="tab"
      >
        {name}
      </a>
    </li>
  );
  return (
    <div
      className="card"
      style={{
        // backgroundColor: "white",
        //overflow: "auto",
        //position: "absolute",
        ...panorama.bottomPanelRect,
        //...BOX_BORDER,
      }}
    >
      <ul className="nav nav-tabs nav-tabs-alt" data-bs-toggle="tabs">
        <Tab name="pins" />
        <Tab name="result" />
        <Tab name="result old" />
      </ul>
      <div className="card-body" style={{ overflow: "auto" }}>
        {tab === "pins" && (
          <pre>
            <code className={`language-js`}>{panorama.cropJsonText}</code>
          </pre>
        )}
        {tab === "result old" && (
          <pre>
            <code className={`language-js`}>{JSON.stringify(panorama.cropInfoOld, null, 2)}</code>
          </pre>
        )}
        {tab === "result" && (
          <pre>
            <code className={`language-js`}>{JSON.stringify(panorama.cropInfo, null, 2)}</code>
          </pre>
        )}
      </div>
    </div>
  );
});
