import React, { useState } from "react";
import {
  Matrix,
  inverse,
  toCSS,
  Point,
  compose,
  translate,
  applyToPoint,
  scale,
} from "transformation-matrix";
import { Pins } from "./Pins";

const scaleBy = 1.1;

type Props = {
  points: [Array<Point>, Array<Point>];
  setPoints(points: [Array<Point>, Array<Point>]): void;
  position: "left" | "right";
  matrix: Matrix;
  setMatrix: (m: Matrix) => void;
  imageSource: string;
  image: HTMLImageElement | null;
  elementRef: any;
  bbox: any;
  selectedPinIndex: number;
  setSelectedPinIndex: (n: number) => void;
};

export const PreviewCanvas = (props: Props) => {
  const {
    points,
    position,
    matrix,
    imageSource,
    image,
    elementRef,
    bbox,
    selectedPinIndex,
    setMatrix,
    setPoints,
    setSelectedPinIndex,
  } = props;
  const inverceMatrix = inverse(matrix);
  const [preventPanning, setPreventPanning] = useState(false);

  const onPointerDown = (evt: any) => {
    if (preventPanning) {
      return;
    }
    //console.info("onPointerDown");
    const downMatix = matrix;
    const downX = evt.nativeEvent.screenX;
    const downY = evt.nativeEvent.screenY;

    const move = (evt) => {
      const m = compose(
        downMatix,
        translate(
          (evt.screenX - downX) / matrix.a,
          (evt.screenY - downY) / matrix.a
        )
      );
      setMatrix(m);
    };

    const up = (evt: any) => {
      //console.info("up");
      window.removeEventListener("pointermove", move);
      window.removeEventListener("pointerup", up);
    };

    window.addEventListener("pointermove", move);
    window.addEventListener("pointerup", up);
  };
  const zoomStage = (event: any) => {
    //event.preventDefault();
    const inv = inverse(matrix);
    const p = [event.nativeEvent.offsetX, event.nativeEvent.offsetY];
    const pointOnImage = applyToPoint(inv, p as Point);
    const scaleUpdate = event.nativeEvent.deltaY > 0 ? 1 / scaleBy : scaleBy;
    const pp = pointOnImage;
    const newMatrix = compose(
      matrix,
      scale(scaleUpdate, scaleUpdate, pp[0], pp[1])
    );

    setMatrix(newMatrix);
  };

  return (
    <div
      ref={elementRef}
      className={`preview-panel-${position}`}
      style={{
        overflow: "hidden",
      }}
    >
      <svg
        style={{
          overflow: "hidden",
          width: bbox.width,
          height: bbox.height,
        }}
        onPointerDown={(evt) => onPointerDown(evt)}
        onWheel={(evt) => zoomStage(evt)}
        onDragOver={(evt) => evt.preventDefault()}
        viewBox={`0 0 ${bbox.width} ${bbox.height}`}
      >
        {image && (
          <>
            <g transform={toCSS(matrix)}>
              <image
                href={image.src}
                width={image.width}
                height={image.height}
              />
            </g>
            <Pins
              points={points}
              setPoints={setPoints}
              selectedPinIndex={selectedPinIndex}
              setSelectedPinIndex={setSelectedPinIndex}
              setPreventPanning={setPreventPanning}
              position={position}
              matrix={matrix}
            />
          </>
        )}
      </svg>
    </div>
  );
};
