import { Point } from "transformation-matrix";
import { PanoramaModel } from "./PanoramaModel";

const calcCenterPoint = (p1, p2) => {
  return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2];
};

export function calcCrop(panorama: PanoramaModel) {
  const result = [];

  const crop = {
    destX: -1,
    destY: 0,
    srcX: 0,
    srcY: 0,
    width: 0,
    height: 0,
  };

  let nextOffset = 0;
  const offY = [];
  let prevY = 0;

  offY.push(prevY);

  for (let index = 0; index < panorama.panels.length - 1; index++) {
    const leftPanel = panorama.panels[index];
    const rightPanel = panorama.panels[index + 1];

    const leftTopPin = leftPanel.pinMap.get("top") as Point;
    const leftBottomPin = leftPanel.pinMap.get("bottom") as Point;
    const rightTopPin = rightPanel.pinMap.get("top") as Point;
    const rightBottomPin = rightPanel.pinMap.get("bottom") as Point;

    const leftCenter = calcCenterPoint(leftTopPin, leftBottomPin);
    const rightCenter = calcCenterPoint(rightTopPin, rightBottomPin);

    let leftDX = leftPanel.image.width - leftCenter[0];
    let rightDX = rightCenter[0];

    let dy = (rightCenter[1] - leftCenter[1]) as number;
    prevY += dy;

    offY.push(prevY);

    let avgDX = (leftDX + rightDX) / 2;

    crop.srcX = nextOffset;
    crop.width = -(nextOffset + avgDX);

    nextOffset = avgDX;
    result.push(crop);
  }

  crop.srcX = nextOffset;
  crop.width = -nextOffset;

  result.push(crop);

  let avgY = 0;
  for (const item of offY) {
    avgY += item;
  }

  avgY /= offY.length;

  //let count = result.size();
  if (result.length != offY.length) {
    throw "unexpected size of result array";
  }

  // move
  for (let index = 0; index < result.length; index++) {
    //int32_t dy;

    let dy = offY[index];
    dy -= avgY;

    if (!dy) continue;

    if (dy > 0) {
      result[index].srcY = dy;
      result[index].height = -dy;
    } else {
      result[index].destY = -dy;
      result[index].height = dy;
    }
  }
  return result;
}
