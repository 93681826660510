import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";

export const Home = observer(() => {
  return (
    <div className="wrapper">
      <div className="page-wrapper">
        <div className="page-body">
          <div className="container">
            <div className="row justify-content-center"></div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Link to="/panorama">Panorama</Link>
                  <br/>
                  <Link to="/panorama_preview">Panorama Tool Preview</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
