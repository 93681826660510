import React from "react";
import { fit } from "./fit";
import {
  applyToPoint,
  compose,
  inverse,
  Matrix,
  Point,
  scale,
} from "transformation-matrix";

type Props = {
  position: "left" | "right";
  matrix: Matrix;
  setMatrix: (m: Matrix) => void;
  image: HTMLImageElement | null;
  bbox: any;
};

export const Footer = (props: Props) => {
  const { position, matrix, setMatrix, bbox, image } = props;

  const zoomStage = (p: Point, by: number) => {
    //event.preventDefault();
    const inv = inverse(matrix);
    const pointOnImage = applyToPoint(inv, p);
    const pp = pointOnImage;
    const newMatrix = compose(matrix, scale(by, by, pp[0], pp[1]));

    return newMatrix;
  };
  return (
    <div className={`footer-${position}`}>
      <button
        style={{ margin: 2 }}
        className="btn btn-md btn-outline-secondary"
        onClick={(evt) => {
          evt.preventDefault();
          setMatrix(zoomStage([bbox.width / 2, bbox.height / 2], 1.1));
        }}
      >
        Zoom In
      </button>
      <button
        style={{ margin: 2 }}
        className="btn btn-md btn-outline-secondary"
        onClick={(evt) => {
          evt.preventDefault();
          setMatrix(zoomStage([bbox.width / 2, bbox.height / 2], 1 / 1.1));
        }}
      >
        Zoom Out
      </button>
      <button
        style={{ margin: 2 }}
        className="btn btn-md btn-outline-secondary"
        onClick={(evt) => {
          evt.preventDefault();
          setMatrix(fit(image, bbox.width, bbox.height));
        }}
      >
        Fit
      </button>
      <button
        style={{ margin: 2 }}
        className="btn btn-md btn-outline-secondary"
        onClick={(evt) => {
          evt.preventDefault();
          setMatrix(zoomStage([bbox.width / 2, bbox.height / 2], 1 / matrix.a));
        }}
      >
        100%
      </button>
    </div>
  );
};
