import React, { CSSProperties } from "react";

type Props = {};

export const PrimaryToolbar = (props: Props) => {
  return (
    <div className="primary-header">
      <div style={{ margin: 5, fontWeight: "bold" }}>Zone #1</div>
      <div style={{ minWidth: 0, maxWidth: "100%" }}></div>
      <div style={{ alignSelf: "center", display: "flex" }}>
        <button
          style={{ margin: 5, maxWidth: 140 }}
          className="btn btn-primary w-100"
        >
          OK
        </button>
        <button
          style={{ margin: 5, maxWidth: 140 }}
          className="btn btn-primary w-100"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
