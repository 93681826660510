import React, { useEffect, useState } from "react";
import { PointsToolbar } from "./PointsToolbar";
import { Point } from "transformation-matrix";
import { isNumber } from "lodash";

type Props = {
  points: [Array<Point>, Array<Point>];
  offset: number;
  setOffset: (v: number) => void;
  setPoints(points: [Array<Point>, Array<Point>]): void;
  selectedPinIndex: number;
  addPoint: () => void;
  setSelectedPinIndex: (n: number) => void;
};

export const SecondaryToolbar = (props: Props) => {
  const {
    points,
    offset,
    setOffset,
    setPoints,
    selectedPinIndex,
    setSelectedPinIndex,
    addPoint,
  } = props;
  const [offsetValue, setOffsetValue] = useState("");
  const [offsetError, setOffsetError] = useState("");
  useEffect(() => {
    setOffsetValue("" + offset);
  }, [offset]);
  return (
    <div
      className="secondary-header"
      style={{
        display: "flex",
        background: "white",
        height: 60,
        borderBottomWidth: 1,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ margin: 5 }}>Put 10 markers on the same objects</div>
      <div style={{ alignSelf: "center", display: "flex" }}>
        <PointsToolbar
          selectedPinIndex={selectedPinIndex}
          addPoint={addPoint}
          setSelectedPinIndex={setSelectedPinIndex}
          points={points}
          setPoints={setPoints}
        />
      </div>
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <label style={{ margin: 5 }} className="form-label">
          Offset
        </label>
        <input
          style={{ width: 100 }}
          type="number"
          className={`form-control ${offsetError && "is-invalid"}`}
          placeholder="offset"
          value={offsetValue}
          onChange={(evt) => {
            setOffsetValue(evt.target.value);
            const num = parseFloat(evt.target.value);
            if (isNumber(num) && num >= 0 && num <= 100) {
              setOffset(num);
              setOffsetError("");
            } else {
              setOffsetError("err");
            }
          }}
        />
        <button
          style={{ margin: 5, maxWidth: 140 }}
          className="btn btn-md btn-outline-secondary"
        >
          Refresh
        </button>
      </div>
    </div>
  );
};
