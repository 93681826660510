import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Panorama from "./Panorama/Panorama";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Home } from "./Home/Home";
import { PanoramaToolPreview } from "./PanoramaToolPreview/PanoramaToolPreview";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/panorama">
          <Panorama />
        </Route>
        <Route path="/panorama_preview">
          <PanoramaToolPreview />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
